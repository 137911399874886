const fr = {
  translation: {
    'Sign in credentials': 'Identifiez-vous',
    'Sign in': 'se connecter',
    'Add Category': 'ajouter une catégorie',
    Title: 'Titre',
    Description: 'La description',
    Add: 'Ajouter',
    Remove: 'Retirer',
    'Add/Remove': 'Ajouter enlever',
    Success: 'Succès',
    Danger: 'Danger',
    Categories: 'Les catégories',
    Image: 'Image',
    Header: 'Entête',
    Loading: 'Chargement',
    Error: 'Erreur',
    Edit: 'modifier',
    Delete: 'Effacer',
    'Edit Category': 'Modifier la catégorie',
    Save: 'sauvegarder',
    'OrderID prefix': 'Préfixe OrderID',
    Order: 'Ordre',
    Email: 'Email',
    Password: 'Mot de passe',
    Enable: 'Activer',
    Disable: 'Désactiver',
    'Enable/Disable': 'Activer désactiver',
    Saving: 'Économie',
    Paypal: 'Pay Pal',
    'Client ID': 'identité du client',
    'Client Secret': 'Secret du client',
    Sandbox: 'bac à sable',
    'Publishable Key': 'Clé publiable',
    'Secret Key': 'Clef secrète',
    'Delivery Charges': 'Frais de livraison',
    Price: 'Prix',
    'Add Food': 'Ajouter de la nourriture',
    Category: 'Catégorie',
    Select: 'Sélectionner',
    Variations: 'Variations',
    Type: 'Type',
    'Food Image': 'Image de nourriture',
    Foods: 'nourriture',
    Actions: 'actes',
    'Edit Food': 'Modifier un aliment',
    OrderID: 'Numéro de commande',
    Orders: 'Ordres',
    Name: 'prénom',
    Items: 'Articles',
    Payment: 'Paiement',
    Status: 'Statut',
    Review: 'La revue',
    'Mark as': 'Marquer comme',
    Users: 'Utilisateurs',
    Phone: 'Téléphone',
    Address: 'Adresse',
    2019: '2019',
    'About Us': 'À propos de nous',
    Blog: 'Blog',
    Welcome: 'Bienvenue',
    Logout: 'Connectez - Out',
    Dashboard: 'Tableau de bord',
    Configuration: 'Configuration',
    Login: "S'identifier",
    Food: 'Aliments',
    'Character limit of max length 50':
      'Limite de caractères de longueur maximale 50',
    'Character limit of max length 15':
      'Limite de caractères de longueur maximale 15',
    'Character limit of max length 60':
      'Limite de caractères de longueur maximale 60',
    'Character limit of max length 20':
      'Limite de caractères de longueur maximale 20',
    'Character limit of max length 140':
      'Limite de caractères de longueur maximale 140',
    Currency: 'Devise',
    'Currency Code': 'Code de devise',
    'Currency Symbol': 'Symbole de la monnaie'
  }
}

export default fr
