const en = {
  translation: {
    'Sign in credentials': 'Sign in credentials',
    'Sign in': 'Sign in',
    'Add Category': 'Add Category',
    Title: 'Title',
    Description: 'Description',
    Add: 'Add',
    Remove: 'Remove',
    'Add/Remove': 'Add/Remove',
    Success: 'Success',
    Danger: 'Danger',
    Categories: 'Categories',
    Image: 'Image',
    Header: 'Header',
    Loading: 'Loading',
    Error: 'Error',
    Edit: 'Edit',
    Delete: 'Delete',
    'Edit Category': 'Edit Category',
    Save: 'Save',
    'OrderID prefix': 'OrderID prefix',
    Order: 'Order',
    Email: 'Email',
    Password: 'Password',
    Enable: 'Enable',
    Disable: 'Disable',
    'Enable/Disable': 'Enable/Disable',
    Saving: 'Saving',
    Paypal: 'Paypal',
    'Client ID': 'Client ID',
    'Client Secret': 'Client Secret',
    Sandbox: 'Sandbox',
    'Publishable Key': 'Publishable Key',
    'Secret Key': 'Secret Key',
    'Delivery Charges': 'Delivery Charges',
    Price: 'Price',
    'Add Food': 'Add Food',
    Category: 'Category',
    Select: 'Select',
    Variations: 'Variations',
    Type: 'Type',
    'Food Image': 'Food Image',
    Foods: 'Foods',
    Actions: 'Actions',
    'Edit Food': 'Edit Food',
    OrderID: 'OrderID',
    Orders: 'Orders',
    Name: 'Name',
    Items: 'Items',
    Payment: 'Payment',
    Status: 'Status',
    Review: 'Review',
    'Mark as': 'Mark as',
    Users: 'Users',
    Phone: 'Phone',
    Address: 'Address',
    2019: '2019',
    'About Us': 'About Us',
    Blog: 'Blog',
    Welcome: 'Welcome',
    Logout: 'Logout',
    Dashboard: 'Dashboard',
    Configuration: 'Configuration',
    Login: 'Login',
    Food: 'Food',
    'Character limit of max length 50': 'Character limit of max length 50',
    'Character limit of max length 15': 'Character limit of max length 15',
    'Character limit of max length 60': 'Character limit of max length 60',
    'Character limit of max length 20': 'Character limit of max length 20',
    'Character limit of max length 140': 'Character limit of max length 140',
    Currency: 'Currency',
    Delivery: 'Delivery',
    'Currency Code': 'Currency Code',
    'Delivery Rate': 'Delivery Rate',
    'Currency Symbol': 'Currency Symbol'
  }
}

export default en
