const km = {
  translation: {
    'Sign in credentials': 'ចូលព័ត៌មានអត្តសញ្ញាណ',
    'Sign in': 'ចូល',
    'Add Category': 'បន្ថែមប្រភេទ',
    Title: 'ចំណងជើង',
    Description: 'ការពិពណ៌នា',
    Add: 'បន្ថែម',
    Remove: 'យកចេញ',
    'Add/Remove': 'បន្ថែម / យកចេញ',
    Success: 'ជោគជ័យ',
    Danger: 'គ្រោះថ្នាក់',
    Categories: 'ប្រភេទ',
    Image: 'រូបភាព',
    Header: 'បឋមកថា',
    Loading: 'កំពុងផ្ទុក',
    Error: 'កំហុស',
    Edit: 'កែប្រែ',
    Delete: 'លុប',
    'Edit Category': 'កែសម្រួលប្រភេទ',
    Save: 'រក្សាទុក',
    'OrderID prefix': 'បុព្វបទ ID លំដាប់',
    Order: 'លំដាប់',
    Email: 'អ៊ីមែល',
    Password: 'ពាក្យសម្ងាត់',
    Enable: 'អនុញ្ញាត',
    Disable: 'បិទ',
    'Enable/Disable': 'អនុញ្ញាត / មិនអនុញ្ញាត',
    Saving: 'កំពុងរក្សាទុក',
    Paypal: 'Paypal',
    'Client ID': 'លេខសម្គាល់អតិថិជន',
    'Client Secret': 'អតិថិជនសម្ងាត់',
    Sandbox: 'Sandbox',
    'Publishable Key': 'កូនសៀវភៅបោះពុម្ព',
    'Secret Key': 'កូនសៀវភៅបោះពុម្ព',
    'Delivery Charges': 'ការដឹកជញ្ជូន',
    Price: 'តម្លៃ',
    'Add Food': 'បន្ថែមអាហារ',
    Category: 'ប្រភេទ',
    Select: 'ជ្រើសរើស',
    Variations: 'បំរែបំរួល',
    Type: 'វាយ',
    'Food Image': 'រូបភាពអាហារ',
    Foods: 'អាហារ',
    Actions: 'សកម្មភាព',
    'Edit Food': 'កែសម្រួលអាហារ',
    OrderID: 'លេខ​សម្គាល់​លំដាប់',
    Orders: 'ការបញ្ជាទិញ',
    Name: 'ឈ្មោះ',
    Items: 'ធាតុ',
    Payment: 'ការទូទាត់',
    Status: 'ស្ថានភាព',
    Review: 'ពិនិត្យឡើងវិញ',
    'Mark as': 'សម្គាល់ថា',
    Users: 'អ្នកប្រើ',
    Phone: 'ទូរស័ព្ទ',
    Address: 'អាសយដ្ឋាន',
    2019: 'ពីរ​ពាន់​ដប់​ប្រាំបួន',
    'About Us': 'អំពី​ពួក​យើង',
    Blog: 'កំណត់ហេតុបណ្ដាញ',
    Welcome: 'ស្វាគមន៍',
    Logout: 'ចាកចេញ',
    Dashboard: 'ផ្ទាំងគ្រប់គ្រង',
    Configuration: 'ការកំណត់​រចនាសម្ព័ន្ធ',
    Login: 'ចូល',
    Food: 'អាហារ',
    'Character limit of max length 50': 'ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 50',
    'Character limit of max length 15': 'ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 15',
    'Character limit of max length 60': 'ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 60',
    'Character limit of max length 20': 'ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 20',
    'Character limit of max length 140': 'ដែនកំណត់តួអក្សរនៃប្រវែងអតិបរមា 140',
    Currency: 'រូបិយប័ណ្ណ',
    'Currency Code': 'លេខកូដរូបិយប័ណ្ណ',
    'Currency Symbol': 'និមិត្តសញ្ញារូបិយប័ណ្ណ'
  }
}

export default km
