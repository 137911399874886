export const subscribePlaceOrder = `subscription SubscribePaceOrder($restaurant:String!){
    subscribePlaceOrder(restaurant:$restaurant){
      userId
      origin
      order{
        _id
        orderId
        restaurant{
          _id
          name
          image
          address
          location{coordinates}
        }
        deliveryAddress{
          location{coordinates}
          deliveryAddress
          details
          label
        }
        items{
          _id
          title
          description
          image
          quantity
          variation{
            _id
            title
            price
            discounted
          }
          addons{
            _id
            options{
              _id
              title
              description
              price
            }
            description
            title
            quantityMinimum
            quantityMaximum
          }
          specialInstructions
          isActive
          createdAt
          updatedAt
        }
        user{
          _id
          name
          phone
          email
        }
        paymentMethod
        paidAmount
        orderAmount
        orderStatus
        status
        paymentStatus
        reason
        isActive
        createdAt
        deliveryCharges
        rider{
          _id
          name
          username
          available
        }
    }
  }
  }`

export const subscriptionOrder = `subscription SubscriptionOrder($id:String!){
    subscriptionOrder(id:$id){
        _id
        orderStatus
        rider{
            _id
        }
    }
  }`
